/* eslint-env jquery */

"use strict";

window.addEventListener("load", () => {
    const Swiper = require("swiper").default;
    const Navigation = require("swiper").Navigation;
    const Pagination = require("swiper").Pagination;

    if (!window.up4ItemsGlideInitialized) {
        const carousels = $(".up-4-items-carousel-swiper");

        carousels.each(function (index, carousel) {
            const selector = "." + $(carousel).data().id + " .swiper";
            const prevButton = "." + $(carousel).data().id + " .swiper-button-prev";
            const nextButton = "." + $(carousel).data().id + " .swiper-button-next";
            const paginationSelector = "." + $(carousel).data().id + " .swiper-pagination";

            // eslint-disable-next-line no-unused-vars
            const up4ItemSwiper = new Swiper(selector, {
                loop: true,
                modules: [Navigation, Pagination],
                pagination: {
                    el: paginationSelector,
                    clickable: true,
                },
                navigation: {
                    nextEl: nextButton,
                    prevEl: prevButton,
                },
                breakpoints: {
                    0: {
                        slidesPerView: 1,
                        spaceBetween: 16,
                    },
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 16,
                    },
                    1024: {
                        slidesPerView: 4,
                        spaceBetween: 16,
                    },
                    1920: {
                        slidesPerView: 4,
                        spaceBetween: 16,
                    },
                },
            });
        });

        window.up4ItemsGlideInitialized = true;
    }
});
